import React, { useState } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { lighten } from 'polished';
import {
  Box,
  Grid,
  Paper,
  List,
  ListItem,
  Typography,
  Button,
  ListItemText,
  Hidden,
  useTheme,
  Divider,
} from '@mui/material';
import { FiPhoneCall, FiMail, FiHome } from 'react-icons/fi';
import { FaViber } from 'react-icons/fa';
import Layout from '../layout';
import Seo from '../components/Seo';
import SocialLinks from '../components/SocialLinks';
import BiHMap from '../components/BiHMap';
import CenteredContainer from '../components/CenteredContainer';

const ContactPage = ({ data }) => {
  const { palette } = useTheme();
  const { strapiKontakt } = data;
  const [selectedOffice, setSelectedOffice] = useState(null);

  return (
    <Layout offset={1}>
      <Seo title={strapiKontakt.naslov} />
      <CenteredContainer>
        <Box textAlign="center" maxWidth={720}>
          <Typography variant="h2" gutterBottom>
            {strapiKontakt.naslov}
          </Typography>
          <Typography variant="subtitle1" component="p" gutterBottom>
            {strapiKontakt.podnaslov}
          </Typography>
        </Box>
        <Paper elevation={1}>
          <Grid container>
            <Grid item md={4}>
              <ContactContainer m={1} px={4} py={3}>
                <Typography variant="h5" gutterBottom>
                  Kontaktirajte nas
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  {strapiKontakt.podnaslov}
                </Typography>
                <Box my={4} display="flex" alignItems="stretch" flexDirection="column">
                  <Typography variant="h6">Kontakt informacije centrale</Typography>
                  <List dense disablePadding>
                    {strapiKontakt.kontakti.map(({ id, naslov, tip, url }) => (
                      <Box key={id}>
                        <ListItem disableRipple component={Button} href={url} target="_blank" rel="noopener noreferrer">
                          <Box mr={2}>
                            {tip === 'telefon' && <FiPhoneCall color="inherit" />}
                            {tip === 'email' && <FiMail color="inherit" />}
                            {tip === 'viber' && <FaViber color="inherit" />}
                          </Box>
                          <ListItemText primary={naslov} />
                        </ListItem>
                      </Box>
                    ))}
                  </List>
                </Box>
                <Hidden xlDown>
                  <Box mb={5}>
                    <Typography variant="h6">
                      Kontakt informacije {selectedOffice ? selectedOffice.name : `filijale`}
                    </Typography>
                    {!selectedOffice && (
                      <Box mt="10px">
                        <Typography variant="body2">Odaberite filijalu na mapi </Typography>
                        {selectedOffice && selectedOffice.name}
                      </Box>
                    )}
                    {selectedOffice?.location.map(({ id, phone, address }) => (
                      <ListItem disableGutters key={id}>
                        <ListItemText
                          secondary={
                            <Box key={id}>
                              <Box>
                                <FiHome color="inherit" /> {address}
                              </Box>
                              <Box mt={1}>
                                <FiPhoneCall color="inherit" /> {phone}
                              </Box>
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                  </Box>
                </Hidden>
                <SocialLinks />
              </ContactContainer>
              <Hidden mdUp>
                <List
                  subheader={
                    <Box ml={5} mt={4}>
                      <Typography variant="h5">Naše filijale</Typography>
                    </Box>
                  }
                >
                  {strapiKontakt.marker.map(({ name, location }) => (
                    <Box key={name} pl={5}>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={name}
                          secondary={location.map(({ id, phone, address }, index) => (
                            <Box key={id}>
                              <Box mt={1}>
                                <FiHome color="inherit" /> {address}
                              </Box>
                              <Box mt={1} mb={index === location.length - 1 ? 0 : 3}>
                                <FiPhoneCall color="inherit" /> {phone}
                              </Box>
                            </Box>
                          ))}
                        />
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
                </List>
              </Hidden>
            </Grid>
            <Hidden xlDown>
              <Grid item md={8}>
                <Box p={6}>
                  <BiHMap
                    mapFillColor={palette.primary.light}
                    mapOutlineColor={palette.divider}
                    markerColor={palette.primary.main}
                    markerTextColor={palette.text.primary}
                    onSelect={(office) => setSelectedOffice(office)}
                    selected={selectedOffice}
                    markers={strapiKontakt.marker}
                  />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Paper>
      </CenteredContainer>
    </Layout>
  );
};

const ContactContainer = styled(Box)`
  ${({ theme }) => `
    border-radius: 6px;
    background-color: ${theme.palette.primary.main};

    h4, h5, h6, svg, span,p {
      color: #fff;
    }

    h6 {
      color: ${lighten(0.5, theme.palette.text.placeholder)};
    }
  `}
`;

export const query = graphql`
  query {
    strapiKontakt {
      naslov
      podnaslov
      kontakti {
        id
        naslov
        podnaslov
        tip
        url
      }
      marker {
        id
        lat
        lon
        name
        location {
          address
          phone
        }
      }
    }
  }
`;

export default ContactPage;
