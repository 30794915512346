import React from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';

const BiHMap = ({ mapFillColor, markerColor, markerTextColor, mapOutlineColor, onSelect, selected, markers }) => {
  return (
    <>
      <ComposableMap
        data-tip=""
        projection="geoAlbers"
        projectionConfig={{
          rotate: [-10, 0, 0, 10],
          center: [7.6, 43.95],
          scale: 13100,
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={mapFillColor}
                stroke={mapOutlineColor}
                style={{
                  default: { outline: 'none' },
                  hover: { outline: 'none' },
                  pressed: { outline: 'none' },
                }}
              />
            ))
          }
        </Geographies>
        {markers.map(({ name, lat, lon, markerOffset = 15, location }) => (
          <Marker
            key={name}
            coordinates={[lon, lat]}
            onClick={() => {
              onSelect({ name, location });
            }}
          >
            <g
              fill="none"
              stroke={selected?.name === name ? '#DD6E42' : markerColor}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(-12, -20)"
            >
              <circle cx="12" cy="10" r="3" />
              <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
            </g>
            <circle cx="0" cy="-8" r="16" fill="transparent" style={{ cursor: 'pointer' }} />
            <text
              textAnchor="middle"
              y={markerOffset}
              style={{
                fontSize: '12px',
                fontFamily: 'Poppins, system-ui, sans-serif',
                fill: markerTextColor,
                userSelect: 'none',
              }}
            >
              {name}
            </text>
          </Marker>
        ))}
      </ComposableMap>
    </>
  );
};

const geoUrl = {
  type: 'Topology',
  objects: {
    xd: {
      type: 'GeometryCollection',
      geometries: [
        {
          type: 'Polygon',
          arcs: [[0]],
          properties: {
            scalerank: 0,
            featurecla: 'Admin-0 sovereignty',
            labelrank: 5,
            sovereignt: 'Bosnia and Herzegovina',
            sov_a3: 'BIH',
            adm0_dif: 0,
            level: 2,
            type: 'Sovereign country',
            admin: 'Bosnia and Herzegovina',
            adm0_a3: 'BIH',
            geou_dif: 0,
            geounit: 'Bosnia and Herzegovina',
            gu_a3: 'BIH',
            su_dif: 0,
            subunit: 'Bosnia and Herzegovina',
            su_a3: 'BIH',
            brk_diff: 0,
            name: 'Bosnia and Herz.',
            name_long: 'Bosnia and Herzegovina',
            brk_a3: 'BIH',
            brk_name: 'Bosnia and Herz.',
            brk_group: null,
            abbrev: 'B.H.',
            postal: 'BiH',
            formal_en: 'Bosnia and Herzegovina',
            formal_fr: null,
            note_adm0: null,
            note_brk: null,
            name_sort: 'Bosnia and Herzegovina',
            name_alt: null,
            mapcolor7: 1,
            mapcolor8: 1,
            mapcolor9: 1,
            mapcolor13: 2,
            pop_est: 4613414,
            gdp_md_est: 29700,
            pop_year: -99,
            lastcensus: 1991,
            gdp_year: -99,
            economy: '6. Developing region',
            income_grp: '3. Upper middle income',
            wikipedia: -99,
            fips_10: null,
            iso_a2: 'BA',
            iso_a3: 'BIH',
            iso_n3: '070',
            un_a3: '070',
            wb_a2: 'BA',
            wb_a3: 'BIH',
            woe_id: -99,
            adm0_a3_is: 'BIH',
            adm0_a3_us: 'BIH',
            adm0_a3_un: -99,
            adm0_a3_wb: -99,
            continent: 'Europe',
            region_un: 'Europe',
            subregion: 'Southern Europe',
            region_wb: 'Europe & Central Asia',
            name_len: 16,
            long_len: 22,
            abbrev_len: 4,
            tiny: -99,
            homepart: 1,
          },
        },
      ],
    },
  },
  arcs: [
    [
      [16.941528768000126, 45.241218974000034],
      [16.947316529000204, 45.23568959600003],
      [16.96798710100012, 45.23729156600008],
      [16.965299926000114, 45.244216207000065],
      [16.961165812000075, 45.250934143000066],
      [16.975945271000086, 45.24716176400007],
      [16.988140909000094, 45.23651641900007],
      [16.99868290200007, 45.231400452000074],
      [17.008294718000172, 45.24416453100012],
      [17.012015421000086, 45.23646474200005],
      [17.013565715000112, 45.23057362900006],
      [17.01253218600013, 45.2244758100001],
      [17.008294718000172, 45.216155905000036],
      [17.014392537000134, 45.21977325500019],
      [17.030205525000127, 45.22695627900008],
      [17.036303344000174, 45.230470276000105],
      [17.05573368300017, 45.20044626900007],
      [17.098418416000158, 45.17765696200006],
      [17.12462843300011, 45.16905775500008],
      [17.18709517400015, 45.148563131000074],
      [17.197843872000163, 45.15295562800007],
      [17.245489543000133, 45.15538442000012],
      [17.249727010000186, 45.159880269000055],
      [17.268950643000096, 45.1895425420001],
      [17.307811320000155, 45.17140411500007],
      [17.326518188000136, 45.165926412000076],
      [17.359384399000163, 45.150681865000124],
      [17.36858280400017, 45.14510081000013],
      [17.382432088000144, 45.13962310900003],
      [17.400828898000128, 45.14101837200012],
      [17.43410852000008, 45.148563131000074],
      [17.43617557800013, 45.15192209900009],
      [17.438759399000133, 45.158123271000065],
      [17.443306925000172, 45.162050680000036],
      [17.451161743000114, 45.15848500600005],
      [17.456949503000175, 45.151818746000046],
      [17.458086385000087, 45.14613433900011],
      [17.454985799000156, 45.14060496000009],
      [17.447751099000186, 45.13430043600009],
      [17.46036014800012, 45.13125152600007],
      [17.472349080000157, 45.13275014200006],
      [17.49622359200015, 45.1416901650001],
      [17.487025187000143, 45.12086456400003],
      [17.48185754400015, 45.114405009000095],
      [17.489298950000176, 45.11626536100009],
      [17.53952844300011, 45.120967916000055],
      [17.561439250000177, 45.11936594700005],
      [17.572394653000146, 45.12039947600003],
      [17.593065226000164, 45.12959788000019],
      [17.629962199000175, 45.15734812500004],
      [17.651562948000162, 45.165357971000034],
      [17.684739217000157, 45.16396270800006],
      [17.714504842000167, 45.1520254520001],
      [17.74137658700016, 45.133266907000106],
      [17.797497193000112, 45.08195221000007],
      [17.81558394400014, 45.07016998300007],
      [17.835737752000114, 45.06443389900009],
      [17.845659628000192, 45.06526072300004],
      [17.875218546000127, 45.07399403900011],
      [17.902607056000164, 45.07776641900004],
      [17.911185343000113, 45.081073711000116],
      [17.927721802000093, 45.09202911400011],
      [17.988493286000107, 45.14380890000008],
      [18.003479451000175, 45.14933827800009],
      [18.01908573400013, 45.1494416310001],
      [18.035002075000136, 45.143343812000055],
      [18.09029585700017, 45.10799713100003],
      [18.112413371000173, 45.10060740200005],
      [18.13184370900015, 45.097868551000076],
      [18.143698040000174, 45.09770000500008],
      [18.143704328000155, 45.09769991600008],
      [18.15365116400008, 45.09755849300011],
      [18.174941854000167, 45.100762431000064],
      [18.19251184100014, 45.108513896000076],
      [18.199333130000184, 45.11900421200012],
      [18.200056599000106, 45.13161326200007],
      [18.2023303630001, 45.144067281000034],
      [18.213285766000183, 45.153782450000094],
      [18.237883748000172, 45.157451477000066],
      [18.261964966000107, 45.150681865000124],
      [18.30744022700017, 45.12753082300013],
      [18.32232303900011, 45.122879944000076],
      [18.392913045000086, 45.11864247600003],
      [18.412033325000095, 45.11244130500005],
      [18.429293254000157, 45.102209372000075],
      [18.451829852000117, 45.08495874500004],
      [18.45184162200013, 45.0849497360001],
      [18.466086873000165, 45.0740457160001],
      [18.481708184000126, 45.066921352000136],
      [18.49078820800017, 45.06278025300007],
      [18.517039835000105, 45.05585561200009],
      [18.541121053000154, 45.0552871710001],
      [18.53926070200015, 45.06934316000003],
      [18.534403117000124, 45.08153879900006],
      [18.534802705000146, 45.0884117180001],
      [18.534919881000178, 45.09042714500008],
      [18.549595988000164, 45.09476796500006],
      [18.557657511000144, 45.09440623000005],
      [18.573780558000124, 45.091564026000086],
      [18.581842082000122, 45.09135732100002],
      [18.588560018000152, 45.09321767200002],
      [18.60251265400015, 45.09926381500007],
      [18.609127238000127, 45.10009063700009],
      [18.616671997000168, 45.09766184500005],
      [18.621116170000107, 45.09311431900018],
      [18.62793745900015, 45.08004018200013],
      [18.6336218670001, 45.07197865800005],
      [18.640546509000075, 45.06536407500006],
      [18.648711385000183, 45.06267690000003],
      [18.658116495000144, 45.0662942500001],
      [18.661217082000093, 45.071100159000125],
      [18.663284139000154, 45.07714630100007],
      [18.6660746660001, 45.08298573900005],
      [18.671345662000135, 45.0868614700001],
      [18.678166951000094, 45.08737823500003],
      [18.6840580650001, 45.08479441400003],
      [18.68901900200015, 45.08024688800002],
      [18.726329386000174, 45.02614166300002],
      [18.738318319000143, 45.01590972900003],
      [18.75010054600014, 45.01224070300013],
      [18.77800581900013, 45.01032867400009],
      [18.7849304610001, 45.00846832300009],
      [18.788961222000097, 45.005729472000056],
      [18.791751749000156, 45.00154368100003],
      [18.794645630000133, 44.99544586200007],
      [18.7951623940001, 44.99332712900009],
      [18.7951623940001, 44.98800445600013],
      [18.79578251200016, 44.98505889900012],
      [18.79795292100013, 44.982216695000076],
      [18.803017212000128, 44.97730743500004],
      [18.804567505000136, 44.97384511300008],
      [18.801570271000116, 44.963974915000094],
      [18.779866170000133, 44.95224436400012],
      [18.772631469000146, 44.942477519000064],
      [18.78338016700019, 44.913745423000066],
      [18.817796672000128, 44.88754547200004],
      [18.84212947600011, 44.876239320000096],
      [18.84214404200017, 44.876232552000076],
      [18.858724406000164, 44.868528545000075],
      [18.889626912000153, 44.86119049100007],
      [19.004968709000195, 44.863309225000066],
      [19.01582076000014, 44.8656346640001],
      [19.04775679500011, 44.8727143360001],
      [19.06842736800013, 44.874833069000076],
      [19.08455041500011, 44.8789671840001],
      [19.174260701000094, 44.92542429700005],
      [19.186869751000103, 44.927543030000024],
      [19.19317427600012, 44.92154856400008],
      [19.19689497900015, 44.913176982000024],
      [19.201855916000085, 44.90837107300011],
      [19.211881144000102, 44.90837107300011],
      [19.22955448400012, 44.913745423000066],
      [19.239476359000122, 44.91514068700003],
      [19.28381473800016, 44.90837107300011],
      [19.293013143000138, 44.909404602000095],
      [19.30128137200009, 44.91235015900007],
      [19.31016971800011, 44.91235015900007],
      [19.330220174000146, 44.89870758100007],
      [19.34014204900015, 44.89622711200009],
      [19.350270629000107, 44.897002259000104],
      [19.35295780500013, 44.898087463000095],
      [19.353164510000113, 44.899017639000064],
      [19.356982304000155, 44.895877196000114],
      [19.35699272600013, 44.89586862300011],
      [19.36277632700012, 44.891111145000096],
      [19.36866744000011, 44.88713206000004],
      [19.37280155400012, 44.88144765300008],
      [19.375695434000107, 44.87312774700011],
      [19.37662561000016, 44.86299916600008],
      [19.373111613000106, 44.86026031500005],
      [19.367840617000184, 44.85927846300005],
      [19.363706503000145, 44.854627584000085],
      [19.328359822000152, 44.733963115],
      [19.318024536000138, 44.715462952000095],
      [19.30841272000015, 44.70512766500009],
      [19.288258911000156, 44.6930353800001],
      [19.277613566000156, 44.684870504000074],
      [19.270068807000115, 44.67562042200005],
      [19.255702759000116, 44.64564809200003],
      [19.20867720500013, 44.58839060500007],
      [19.20412967900012, 44.58513499000007],
      [19.193277628000146, 44.58058746400003],
      [19.188316691000097, 44.57603993700009],
      [19.18624963300013, 44.56932200200016],
      [19.187283162000114, 44.55330230700012],
      [19.185319458000095, 44.546222636000024],
      [19.179635051000133, 44.53826446500004],
      [19.17312382000017, 44.5314431770001],
      [19.165269002000144, 44.52668894500009],
      [19.12992232300013, 44.51831736300004],
      [19.12733850000015, 44.50255605100013],
      [19.14335819500019, 44.458217672000174],
      [19.141394490000152, 44.43082916300004],
      [19.129612264000144, 44.416153056000056],
      [19.115762980000085, 44.40359568300006],
      [19.107184693000164, 44.38271840400007],
      [19.108941691000126, 44.36364980100011],
      [19.11669315600014, 44.34370269800009],
      [19.13891402200008, 44.309337871000125],
      [19.157000773000107, 44.29357655900007],
      [19.177154582000156, 44.28696197600006],
      [19.220046020000126, 44.280244039000124],
      [19.240716594000133, 44.27269928000017],
      [19.24950158700014, 44.270735576000064],
      [19.263660930000185, 44.27016713500011],
      [19.295907023000126, 44.27579986600004],
      [19.307379191000166, 44.27419789599999],
      [19.324329061000157, 44.2639659630001],
      [19.341588989000144, 44.24582753500009],
      [19.353991333000124, 44.22433014000005],
      [19.3560583900001, 44.20402130100011],
      [19.36236291500009, 44.19120554700005],
      [19.3811731360002, 44.17709788000012],
      [19.424891398000085, 44.15379181000007],
      [19.43553674300017, 44.146092021000086],
      [19.4425647380001, 44.14319814],
      [19.44845585100009, 44.144438375000036],
      [19.45972131300016, 44.15270660400007],
      [19.465715779000078, 44.15280995700003],
      [19.474190714000173, 44.14490346300006],
      [19.476361125000068, 44.1270234170001],
      [19.482665649000182, 44.120667217000076],
      [19.498168579000094, 44.11022857700006],
      [19.51618948700016, 44.091193506000096],
      [19.522043091000086, 44.085010478000086],
      [19.554599243000126, 44.07126454700007],
      [19.570928995000173, 44.05689849900004],
      [19.580230753000194, 44.05152415000008],
      [19.583848104000168, 44.05431467800004],
      [19.58994592300013, 44.06030914400007],
      [19.59893762200008, 44.06258290600009],
      [19.611443319000074, 44.05452138300011],
      [19.6188847250001, 44.035711161000094],
      [19.61040979000009, 44.01927805700002],
      [19.593356567000143, 44.005583802],
      [19.55222212700008, 43.98341461200009],
      [19.528554321000144, 43.9772134410001],
      [19.504059692000112, 43.97566314700016],
      [19.44711226400011, 43.97979726200002],
      [19.393988892000095, 43.97705841100007],
      [19.379106079000138, 43.9738544720001],
      [19.364636678000124, 43.97328603100003],
      [19.35171757000012, 43.979125468000106],
      [19.32587935400008, 43.99659210200015],
      [19.300557902000094, 44.00951121000007],
      [19.287328735000102, 44.01302520800003],
      [19.27275598100016, 44.01240509000006],
      [19.25208540900013, 44.007495830000025],
      [19.243507121000107, 44.002018128000216],
      [19.238029419000128, 43.99250966400005],
      [19.238132771000153, 43.98537831600011],
      [19.242887003000135, 43.97282094400002],
      [19.240716594000133, 43.96574127200007],
      [19.229347778000147, 43.9576797480001],
      [19.24102665200013, 43.95235707700006],
      [19.27544315600008, 43.933262634000144],
      [19.305932251000172, 43.904737244],
      [19.359469035000103, 43.84220876100004],
      [19.461685018000168, 43.76213612900007],
      [19.48173547300013, 43.7292182420001],
      [19.505713338000135, 43.67364023900011],
      [19.5073669840001, 43.647181905000124],
      [19.48173547300013, 43.62891428700006],
      [19.47749800600016, 43.61697703000003],
      [19.475637654000167, 43.60255930600009],
      [19.47687788900012, 43.58868418400003],
      [19.48173547300013, 43.578116353000055],
      [19.4889701740002, 43.57318125500011],
      [19.491760702000164, 43.56860789000008],
      [19.489590291000155, 43.564447937000054],
      [19.48173547300013, 43.56083058700007],
      [19.443908325000137, 43.57186350600007],
      [19.43181604000017, 43.571140035000056],
      [19.419310344000166, 43.54925506700003],
      [19.410732056000143, 43.54075429300012],
      [19.4028772380002, 43.54964263900011],
      [19.394609009000163, 43.56646331800009],
      [19.380449666000118, 43.5855835980001],
      [19.36339644300017, 43.60157745400004],
      [19.346549926000193, 43.60883799200012],
      [19.335801228000093, 43.60651255300009],
      [19.312650187000173, 43.593154195000054],
      [19.30086796000009, 43.58829661100005],
      [19.289189087000125, 43.58793487500013],
      [19.263660930000185, 43.590957947000064],
      [19.252705525000096, 43.588632507000014],
      [19.23885624200014, 43.57212188700005],
      [19.22945113100019, 43.54974599200007],
      [19.217462199000124, 43.53279612200005],
      [19.195344686000112, 43.53279612200005],
      [19.14769901500017, 43.5381446330001],
      [19.1227909750001, 43.535948385000026],
      [19.09602258300012, 43.51290069600013],
      [19.076385538000096, 43.507242127000026],
      [19.054991495000166, 43.50669952400007],
      [19.038144979000094, 43.51101450700013],
      [19.025019165000145, 43.523029277],
      [19.01427046700013, 43.53778289800009],
      [19.0002144770001, 43.547885641000065],
      [18.977476847000133, 43.5462319950001],
      [18.962697388000095, 43.53829966200006],
      [18.938926229000145, 43.518946839000094],
      [18.920529419000133, 43.51220306400005],
      [18.91102095500017, 43.507267965000025],
      [18.90512984200015, 43.49905141300009],
      [18.905646607000108, 43.49062815400008],
      [18.915258423000125, 43.48538299600007],
      [18.93065799900009, 43.482282410000025],
      [18.937892700000162, 43.47894928000004],
      [18.951225219000094, 43.463808085000025],
      [18.950708455000154, 43.45729685500005],
      [18.946367635000172, 43.44908030300009],
      [18.94626428200013, 43.44393849700002],
      [18.968278443000116, 43.44809845000013],
      [18.975306437000143, 43.44427439400009],
      [19.00992964700015, 43.41099477200005],
      [19.039591919000202, 43.350714213000074],
      [19.06966760300017, 43.3088304650001],
      [19.06253625500014, 43.304386292000075],
      [19.0362846270001, 43.30332692500009],
      [19.02470910600016, 43.298727723000056],
      [19.02253515300012, 43.29654632500005],
      [19.01716434700012, 43.29115712500008],
      [19.010859823000175, 43.282320455000175],
      [19.002901652000133, 43.27394887300004],
      [18.99246301300019, 43.26710174600005],
      [18.989505697000197, 43.27201610900006],
      [18.988949015000145, 43.27294118300004],
      [18.968588501000113, 43.292216492000094],
      [18.95949344900012, 43.30345611600005],
      [18.9571163330001, 43.3118276980001],
      [18.957943156000113, 43.318984884000045],
      [18.95732303800014, 43.32585785000002],
      [18.950295044000143, 43.333066712000075],
      [18.92342329900012, 43.34683848100012],
      [18.899135376000146, 43.3519027710001],
      [18.839604126000154, 43.34782033300009],
      [18.82120731600017, 43.341386617000055],
      [18.824617960000182, 43.33740753200006],
      [18.830302368000133, 43.32815745000006],
      [18.833506307000107, 43.32415252700007],
      [18.807151326000138, 43.31802887],
      [18.679510539000148, 43.24948008200006],
      [18.66431766700012, 43.23317616800013],
      [18.68839888500017, 43.22446869000008],
      [18.64530074100017, 43.18018198600005],
      [18.62907434100015, 43.15488637300008],
      [18.621127821000186, 43.12457815200021],
      [18.62059940600014, 43.1225627650001],
      [18.621116170000107, 43.0964403280001],
      [18.638996215000162, 43.020243429000075],
      [18.598378540000112, 43.02445505800004],
      [18.538950643000163, 43.02388661700009],
      [18.483036743000184, 43.014636536000054],
      [18.45275435400015, 42.99339752200008],
      [18.433530721000125, 42.954200949000054],
      [18.434047486000168, 42.93688934400005],
      [18.443969360000068, 42.9168647260001],
      [18.467637166000173, 42.881698914000125],
      [18.473321574000124, 42.86255279500013],
      [18.46598352000015, 42.852811788000096],
      [18.453477824000146, 42.84565460300006],
      [18.443659302000185, 42.8344666550001],
      [18.444589477000136, 42.81707753600007],
      [18.453891235000157, 42.79315134700009],
      [18.467430460000145, 42.76919932100016],
      [18.502157023000166, 42.72744476300004],
      [18.522517537000112, 42.7118901570001],
      [18.53967411300016, 42.69545705200004],
      [18.550009400000164, 42.6680685430001],
      [18.549595988000164, 42.638923035000076],
      [18.54283232300017, 42.62642904400006],
      [18.53843387800012, 42.61830413900016],
      [18.51724654200018, 42.60298207700002],
      [18.506797873000096, 42.59849020800006],
      [18.48665409300017, 42.589830424000056],
      [18.493682088000156, 42.57983103400004],
      [18.495852498000147, 42.570865174000076],
      [18.492131795000148, 42.564767355000114],
      [18.481796509000134, 42.56368214900003],
      [18.47053104700018, 42.5690564990001],
      [18.458955525000135, 42.56972829200008],
      [18.44769006400017, 42.56621429500011],
      [18.437354777000166, 42.55921213800012],
      [18.42102502500012, 42.56363047300003],
      [18.3852649330002, 42.56652435300002],
      [18.37048547400019, 42.57362986300005],
      [18.369790593000147, 42.5743840840001],
      [18.369782537000106, 42.57439282800003],
      [18.349608195000116, 42.596289979000105],
      [18.338652791000158, 42.60251698900008],
      [18.311574341000096, 42.60122507800021],
      [18.257107381000196, 42.61489349400004],
      [18.224137817000127, 42.62807098500021],
      [18.049368124000154, 42.71475820000008],
      [17.99531457500015, 42.740441387000075],
      [17.971853475000103, 42.754962464000116],
      [17.928858684000204, 42.79080006900004],
      [17.90446740700017, 42.804546],
      [17.86943078600015, 42.81180653900013],
      [17.85847538200008, 42.81697418200007],
      [17.827159464000175, 42.85312184700008],
      [17.825092408000103, 42.86454233900007],
      [17.82622928800012, 42.888649394000126],
      [17.823335408000133, 42.89836456300007],
      [17.81175988700008, 42.9098625700001],
      [17.802664836000105, 42.909630026000045],
      [17.79470666500015, 42.904591573000076],
      [17.786748495000097, 42.90138763500009],
      [17.765457804000107, 42.90412648600012],
      [17.72359989400013, 42.91624460900012],
      [17.70158573400016, 42.91950022500002],
      [17.67946822100015, 42.915676168000076],
      [17.66592899600016, 42.90516001400003],
      [17.653349062064194, 42.89092833614073],
      [17.653330925000148, 42.890936591000084],
      [17.65284264400009, 42.89118073100015],
      [17.556488477000073, 42.93475983300006],
      [17.57398522200012, 42.934271552000084],
      [17.60108483200014, 42.925238348000065],
      [17.61793053500014, 42.921779690000065],
      [17.60303795700011, 42.93256256700006],
      [17.58067872909183, 42.94207540221545],
      [17.63450972400014, 42.950402731000096],
      [17.662725057000102, 42.965698955000065],
      [17.659314412000157, 42.99339752200008],
      [17.659211060000132, 42.99344919800011],
      [17.659107707000203, 42.99363006600005],
      [17.659004354000075, 42.99365590500007],
      [17.628411905000178, 43.046572571000056],
      [17.598025500000148, 43.07290235300006],
      [17.587380818000128, 43.08212595700009],
      [17.45091327000017, 43.14815159700008],
      [17.44206669100015, 43.15243174300009],
      [17.426770467000097, 43.165712585000094],
      [17.415194947000145, 43.18496205700005],
      [17.406823365000065, 43.20542592400018],
      [17.400208781000174, 43.2160454310001],
      [17.389666788000113, 43.223099264],
      [17.3694096280002, 43.23255605100006],
      [17.328688599000145, 43.260357971000204],
      [17.2899312750001, 43.30343027800002],
      [17.267607055000127, 43.35332387300009],
      [17.28641727700014, 43.43737559000003],
      [17.270500936000133, 43.46321380700006],
      [17.2394950770001, 43.47840667700008],
      [17.14275679500011, 43.489336243000125],
      [17.084569132000098, 43.51323659300003],
      [17.062314068000177, 43.5277470650001],
      [17.03051558500013, 43.54847991900003],
      [16.981939738000108, 43.5896918750001],
      [16.82380985500015, 43.70730743400007],
      [16.712602173000136, 43.77151540100003],
      [16.698236125000136, 43.788077698000095],
      [16.689761190000127, 43.80931671200007],
      [16.686143839000152, 43.826137390000056],
      [16.6785990810001, 43.840684306000114],
      [16.637361288000193, 43.86840871200012],
      [16.604805135000134, 43.90106821700003],
      [16.55581587700013, 43.9374742640001],
      [16.527446673000128, 43.96785917400008],
      [16.51654178800007, 43.97953888000005],
      [16.501038859000147, 43.992716370000025],
      [16.483468872000174, 44.00253489200007],
      [16.439647258000093, 44.01400706000008],
      [16.431482381000166, 44.02578928700011],
      [16.426934855000127, 44.04051707000009],
      [16.41401574700012, 44.05581329300007],
      [16.403990519000104, 44.058758850000046],
      [16.37794559700012, 44.05736358700007],
      [16.36719689900019, 44.05813873300009],
      [16.357378377000117, 44.06216949500008],
      [16.346112915000163, 44.06847401900011],
      [16.32688928200014, 44.08237498000007],
      [16.312213175000153, 44.09999664400006],
      [16.289992309000127, 44.1391157020001],
      [16.27552290800017, 44.15735748300004],
      [16.232114705000185, 44.19099884000009],
      [16.21578495300014, 44.208155416000054],
      [16.197078084000168, 44.25115020800011],
      [16.187569620000176, 44.28241445000002],
      [16.18663944500014, 44.29760732000007],
      [16.189533325000127, 44.30851104800011],
      [16.19966190600016, 44.32468577100012],
      [16.20317590300013, 44.333057353000086],
      [16.205863078000164, 44.34980051700005],
      [16.205643642000126, 44.35053070800005],
      [16.202865844000144, 44.35977406800005],
      [16.19211714600013, 44.36742218100004],
      [16.17134322100017, 44.37708567300005],
      [16.15315311700013, 44.38044464200006],
      [16.141991008000105, 44.380754700000054],
      [16.138270305000106, 44.377602438000096],
      [16.13878706900013, 44.38540557900011],
      [16.145401652000118, 44.38959137000013],
      [16.153876587000127, 44.39357045500013],
      [16.15987105300016, 44.400701803000075],
      [16.159664347000103, 44.41610138000006],
      [16.15294641100016, 44.435066630000094],
      [16.128348429000198, 44.48431427100009],
      [16.123077433000162, 44.50389963800011],
      [16.116462850000147, 44.52146962500008],
      [16.10488732900012, 44.532373353000125],
      [16.083699991000145, 44.53490549700011],
      [16.047319783000177, 44.523329977000074],
      [16.02664921000016, 44.52519032800008],
      [16.00608199000007, 44.54100331600007],
      [16.0139368090002, 44.557023011000055],
      [16.030473266000115, 44.57221588200015],
      [16.0349174400001, 44.58539337200004],
      [16.043185669000138, 44.58844228200009],
      [16.044735962000175, 44.589372457000124],
      [16.041118612000105, 44.60280833000009],
      [16.03615767400015, 44.615314026000064],
      [16.028302856000124, 44.62471913700004],
      [16.016003865000158, 44.628956604000095],
      [15.991612589000141, 44.63143707300007],
      [15.979106893000136, 44.63438263000005],
      [15.968978312000104, 44.63924021400008],
      [15.95564579200007, 44.65319285100007],
      [15.948101034000132, 44.67810089100003],
      [15.937352336000089, 44.688952942000085],
      [15.929807577000133, 44.68946970700004],
      [15.921539347000191, 44.686110739000114],
      [15.913064412000097, 44.68450876900009],
      [15.905416301000116, 44.690141500000024],
      [15.902005656000199, 44.69882314100006],
      [15.899318481000165, 44.709210103000075],
      [15.895597778000138, 44.71856353700021],
      [15.888776489000094, 44.72424794500007],
      [15.8762707920001, 44.72481638600003],
      [15.869242798000187, 44.71856353700021],
      [15.863868449000137, 44.70998525000009],
      [15.85642704300011, 44.703577372000055],
      [15.847848755000172, 44.70151031500011],
      [15.829968709000127, 44.70052846300011],
      [15.82035689300011, 44.69825470000019],
      [15.805680786000124, 44.69665273000008],
      [15.79699914600016, 44.70326731400009],
      [15.790487915000199, 44.713395895000204],
      [15.782839803000115, 44.722594300000125],
      [15.75927535000011, 44.734169820000076],
      [15.753694295000088, 44.73928578700007],
      [15.728476196000145, 44.76910308800005],
      [15.717314087000204, 44.78646637000007],
      [15.716073852000164, 44.80320953400012],
      [15.730646607000125, 44.817007142000065],
      [15.751213827000129, 44.82155466700003],
      [15.76868046000007, 44.82734242800008],
      [15.773434692000166, 44.845015768000096],
      [15.764029581000102, 44.86403269500006],
      [15.748009888000155, 44.882016094000036],
      [15.73426395700011, 44.9021182260001],
      [15.730853312000193, 44.92723297200011],
      [15.734108149000093, 44.93444011200002],
      [15.738811482000159, 44.94485463500011],
      [15.763616170000176, 44.97555043600006],
      [15.767440226000133, 44.99322377600005],
      [15.75555464700011, 45.023557841000084],
      [15.75596805900011, 45.04221303300007],
      [15.77942915800017, 45.0853628550001],
      [15.78376997900017, 45.100917460000105],
      [15.780049276000142, 45.160293681000056],
      [15.780979451000121, 45.16799347000004],
      [15.792348267000193, 45.18980092400007],
      [15.802568519000175, 45.196473382000065],
      [15.824801066000106, 45.21098826100011],
      [15.878854614000204, 45.21724110900011],
      [15.974559367000097, 45.21538075800011],
      [15.997503703000149, 45.218533021000084],
      [16.008459106000117, 45.21817128500008],
      [16.020448039000172, 45.213933818000115],
      [16.029233032000064, 45.205045472000094],
      [16.028716268000125, 45.19626047800004],
      [16.024995565000125, 45.18892242500003],
      [16.024375447000153, 45.18432322200009],
      [16.03305708800019, 45.17972402000002],
      [16.054967896000164, 45.17641672800012],
      [16.06747359200014, 45.16959543900016],
      [16.08276981600011, 45.15161204100009],
      [16.10406050600011, 45.11254465800009],
      [16.121527140000126, 45.09616322900011],
      [16.212994426000193, 45.03146433500005],
      [16.24120975700015, 45.01895863900005],
      [16.279346965000116, 45.00717641200005],
      [16.316243937000138, 45.00123362300012],
      [16.34063521300007, 45.00593617800011],
      [16.34601939200016, 45.0200100120001],
      [16.346023380000133, 45.02002043500006],
      [16.356964966000106, 45.0486209110001],
      [16.377325480000138, 45.07559600900005],
      [16.381976359000106, 45.08370920800006],
      [16.384043416000168, 45.09543975900003],
      [16.381976359000106, 45.10195098900009],
      [16.381769654000152, 45.10758372000002],
      [16.389211060000065, 45.116833801000055],
      [16.398512817000096, 45.12143300400008],
      [16.424764445000136, 45.12727244100007],
      [16.43551314300015, 45.132853496000095],
      [16.449155721000153, 45.14809804300005],
      [16.481918579000165, 45.1995160930001],
      [16.528944133000124, 45.22225372400017],
      [16.587028443000122, 45.22085846],
      [16.786292765000155, 45.1788455210001],
      [16.81171757000007, 45.18122263700012],
      [16.82422326600016, 45.189077454000156],
      [16.825773560000187, 45.196363831000056],
      [16.825050089000086, 45.205045472000094],
      [16.830734497000122, 45.21698272700006],
      [16.84075972500017, 45.224010722000074],
      [16.85192183400011, 45.227421367000076],
      [16.861740357000144, 45.232227274000095],
      [16.867321411000177, 45.24338938500004],
      [16.875382935000175, 45.2469550580001],
      [16.885821574000204, 45.25522328700005],
      [16.893986450000114, 45.263543192],
      [16.896983683000144, 45.268142396000044],
      [16.92437219300018, 45.28452382500011],
      [16.932640421000116, 45.27878774100013],
      [16.940701945000114, 45.25775543300004],
      [16.94214888500011, 45.249848939000074],
      [16.941528768000126, 45.241218974000034],
    ],
  ],
  bbox: [15.716073852000164, 42.55921213800012, 19.6188847250001, 45.28452382500011],
};

export default React.memo(BiHMap);
